/* editor.card */

.editor_card {
  font-family: "Nunito Sans", sans-serif;
}

.editor_card.box-shadow {
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}

.allcards_card-shadow {
  box-shadow: 3px 5px 20px 2px rgb(0 0 0 / 25%);
}

.profile_input-shadow {
  --tw-ring-offset-shadow: 5 0 #0000;
  --tw-ring-offset-shadow: 5 0 #0000;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.reveal_edit-btn:hover .edit-btn,
.reveal_edit-btn:hover ~ .edit-btn {
  opacity: 1;
}

.gradient-text {
  background: -webkit-linear-gradient(#eee, #6e6363);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient_gold-text {
  background: -webkit-linear-gradient(#e6e3e3, #e6ad12);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flip_inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip_card {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.loader_icon {
  animation: loader_animation 1s ease-in-out 0.1s infinite;
}

@keyframes loader_animation {
  0% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translateZ(0px);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5) rotate(180deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translateZ(0px);
  }
}
